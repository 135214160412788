<template>
  <div class="pageContol shopDecorate">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业内训</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">店铺装修</a>
        </span>
      </div>

      <div class="framePage-halfBody">
        <div class="framePage-body">
          <div class="framePage-scroll">
            <div class="ovy-a">
              <div class="flexjc">
                <div class="phonebox flexdc" style="position: relative">
                  <div class="phone_top">
                    <div>
                      <span style="cursor: pointer" @click="editTitle()"
                        >{{ title1 }} <i class="el-icon-edit"></i
                      ></span>
                    </div>
                    <div class="flexcb setBox">
                      <i class="el-icon-more"></i>
                      <el-divider direction="vertical"></el-divider>
                      <i class="el-icon-remove-outline"></i>
                    </div>
                  </div>
                  <div
                    style="overflow-y: scroll"
                    class="phone_content"
                    ref="appbox"
                  >
                    <div
                      class="phone_banner df"
                      @mouseenter="modalShow('1', '10')"
                      @mouseleave="modalNone('1', '20')"
                      id="banner"
                    >
                      <el-carousel
                        style="width: 100%"
                        height="150px"
                        loop
                        :interval="2000"
                        v-if="bannerList.length"
                      >
                        <el-carousel-item
                          v-for="(item, index) in bannerList.filter(
                            (el) => !el.hidden
                          )"
                          :key="index"
                        >
                          <img
                            style="width: 100%; height: 100%"
                            :src="item.bannerURL"
                            alt=""
                          />
                        </el-carousel-item>
                      </el-carousel>
                      <div v-else style="" class="flexdcc flex1">
                        <span>轮播图位置</span>
                      </div>
                      <div class="modal" v-if="show1">
                        <div>
                          <img
                            src="@/assets/app_home/app_edit.png"
                            @click="editBanner('banner')"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="Selected"
                      id="Selected"
                      @mouseenter="modalShow('4', '10')"
                      @mouseleave="modalNone('4', '20')"
                    >
                      <div class="top">
                        <span>{{ title2 }} </span>
                      </div>
                      <template v-if="messageList.length">
                        <div
                          class="course_list df"
                          v-for="(item, index) in messageList"
                          :key="index"
                        >
                          <p class="ellipsis">{{ item.informationName }}</p>
                          <p class="informationDate">{{ item.updateTime }}</p>
                        </div>
                      </template>
                      <div v-else>无数据</div>
                      <div class="modal" v-if="show4">
                        <div>
                          <img
                            src="@/assets/app_home/app_edit.png"
                            @click="editBanner('information')"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="phone_bot flexca flexac">
                    <span style="font-weight: 700">首页</span>
                    <span>学习</span>
                    <span>我的</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="framePage-article">
          <div style="padding: 1rem; display: flex; justify-content: end">
            <el-button
              size="small"
              v-if="stu == 'information'"
              @click="doAddInformation()"
              >添加资讯</el-button
            >
            <el-button class="bgc-bv" size="small" v-if="stu" @click="doAdd()"
              >保存</el-button
            >
          </div>
          <div class="ovy-a" v-if="stu == 'banner'">
            <div class="addList">
              <div
                class="df card-border form-box"
                v-for="(item, index) in addList"
                :key="index"
              >
                <el-form
                  :model="item"
                  ref="cardform"
                  label-width="7rem"
                  class="form form-bg"
                >
                  <el-form-item
                    label="图片"
                    prop="qualificationImageId"
                    :rules="[
                      {
                        required: true,
                        message: '请上传缩略图',
                        trigger: ['change', 'blur'],
                      },
                    ]"
                  >
                    <el-upload
                      class="imgUpload"
                      action
                      :on-change="
                        (res, file) => {
                          handleAvatarSuccess2(res, file, index);
                        }
                      "
                      :before-upload="$beforeAvatarUpload"
                      :show-file-list="false"
                      :http-request="$requestMine"
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <img
                        v-if="item.bannerURL"
                        :src="item.bannerURL"
                        oncontextmenu="return false;"
                        ondragstart="return false;"
                        class="imgCenter"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="跳转地址" prop="bannerPath">
                    <el-input v-model="item.bannerPath" size="small"></el-input>
                  </el-form-item>
                </el-form>
                <div class="df zsBtn">
                  <el-button
                    v-if="addList.length > 1"
                    @click="delCertificate(index)"
                    type="primary"
                    size="small"
                    round
                    class="bgc-bv"
                    :disabled="item.disabled"
                    >删除</el-button
                  >
                  <el-button
                    v-if="index == addList.length - 1"
                    @click="certificate"
                    size="small"
                    type="primary"
                    round
                    class="bgc-bv"
                    >添加轮播图</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="ovy-a" v-if="stu == 'information'" style="height: 90%">
            <div>
              <div class="information-container">
                <div
                  v-for="(item, index) in InformationArr"
                  :key="index"
                  class="container"
                >
                  <div class="formation">
                    <p class="ellipsis">{{ item.informationName }}</p>
                    <p class="informationDate">{{ item.updateTime }}</p>
                  </div>
                  <div class="deleteBtn">
                    <el-button
                      class="bgc-redd"
                      size="small"
                      @click="doDelete(item.informationId, index)"
                      >删除</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <relationInformation
      v-if="themeId"
      ref="relationInformation"
      :themeId="themeId"
      @eventBus="relationInformationBack"
    />
    <!-- 编辑标题 -->
    <el-dialog
      title="编辑标题"
      :visible.sync="titleShowDialog"
      width="35%"
      center
    >
      <div class="flexjc flexac">
        <span style="min-width: 20%; text-align: center">标题名称：</span>
        <el-input v-model="commentTitle"></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="titleEditCancel">取 消</el-button>
        <el-button type="primary" @click="titleEditOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import relationInformation from "@/views/CustomPrograms/shopDecoration/relationInformation.vue";
export default {
  name: "operate/rightsManagement",
  components: {
    relationInformation,
  },
  data() {
    return {
      stu: "", //状态
      themeId: "", //主题Id
      moduleId: "", //模块Id
      addList: [],
      addObj: {
        bannerPath: "", //跳转地址
        bannerKey: "", //图片OSS id1
        bannerURL: "", //图片OSS路径
      },
      title1: "",
      title2: "新闻资讯",
      // 模块显隐
      block1: true, // 轮播图
      block4: true, // 精选推荐
      // 划过蒙层
      show1: false, // 轮播图
      show4: false, // 精选推荐
      bannerList: [], //轮播图集合
      messageList: [], //新闻资讯集合
      InformationArr: [], //新闻资讯选择集合
      informationIds: [], //新闻资讯id集合
      titleShowDialog: false, //标题名称显示
      commentTitle: "", //标题名称
    };
  },
  created() {
    this.getTheme();
    this.addList.push({ ...this.addObj });
  },
  methods: {
    getTheme() {
      this.$post("/biz/et/theme/getTheme", {}).then((res) => {
        if (res.status == 0) {
          let data = res.data || {};
          this.bannerList = data.bannerList || [];
          this.messageList = data.messageList || []; // 新闻资讯集合
          data.messageList.map((item) => {
            this.informationIds.push(item.informationId);
          });
          this.title1 = data.title || this.title1; //
          this.themeId = data.themeId; //
          data.items.forEach((el) => {
            if (el.code == 10) {
              this.moduleId = el.moduleId;
            }
            if (el.module == 20) {
              this.moduleId = el.moduleId;
            }
          });
        }
      });
    },
    // 划过显示
    modalShow(key, stu) {
      this["show" + key] = true;
    },
    // 移除隐藏
    modalNone(key, stu) {
      this["show" + key] = false;
    },

    // 编辑轮播图
    editBanner(stu) {
      this.stu = stu;
      if (stu == "information") {
        this.InformationArr = this.messageList;
      } else {
        if (this.bannerList.length) {
          this.addList = this.bannerList;
        } else {
          this.addList = [{ ...this.addObj }];
        }
      }
      this.$forceUpdate();
      //   this.bannerDialog = true;
    },
    doAdd() {
      if (this.stu == "information") {
        let params = {
          themeId: this.themeId,
          moduleId: this.moduleId,
          informationIds: this.informationIds,
        };
        this.$post("/biz/et/theme/saveMessage", params).then((res) => {
          if (res.status == "0") {
            this.$message.success(res.message);
            this.getTheme();
            this.stu = "";
          }
        });
      } else {
        let params = {
          themeId: this.themeId,
          moduleId: this.moduleId,
          bannerList: this.addList,
        };
        this.$post("/biz/et/theme/saveOrUpdateBanner", params).then((res) => {
          if (res.status == "0") {
            this.$message.success(res.message);
            this.getTheme();
            this.stu = "";
          }
        });
      }
    },
    //资讯删除
    doDelete(informationId, index) {
      this.InformationArr.splice(index, 1);
      this.informationIds = [];
      this.InformationArr.map((el) => {
        this.informationIds.push(el.informationId);
      });
    },
    // 编辑标题 方法
    editTitle() {
      this.commentTitle = this.title1;
      this.titleShowDialog = true;
    },
    titleEditCancel() {
      this.commentTitle = "";
      this.titleShowDialog = false;
    },
    titleEditOk() {
      this.$post("/biz/et/theme/updateTitle", {
        title: this.commentTitle,
        themeId: this.themeId,
      }).then((res) => {
        if (res.status == "0") {
          this.title1 = this.commentTitle;
          this.$message.success(res.message);
        }
      });
      this.titleShowDialog = false;
    },
    // 新增 -- banner
    certificate() {
      this.addList.push({ ...this.addObj });
    },
    // 删除 -- banner
    delCertificate(ind) {
      this.addList.splice(ind, 1);
      this.$forceUpdate();
    },
    //上传图片
    handleAvatarSuccess2(res, file, index) {
      this.uploadFetch(res.raw, index);
    },
    uploadFetch(file, index) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      console.log(file.size)
       if (file.size / 1024 / 1024 > 1) {
        this.$message.error("文件大小不能超过1MB");
        return;
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.addList.forEach((ele, ind) => {
          if (index === ind) {
            ele.bannerKey = ret.data.fileKey || "";
            ele.bannerURL = ret.data.fileURL || "";
          }
        });
      });
    },
    //添加资讯
    doAddInformation() {
      this.$refs.relationInformation.showPopUp();
    },
    //添加资讯回调
    relationInformationBack(ret) {
      this.InformationArr = this.InformationArr.concat(ret);
      this.InformationArr.map((el) => {
        this.informationIds.push(el.informationId);
      });
      //数组去重
      this.informationIds = [...new Set(this.informationIds)];
    },
  },
};
</script>

<style lang="less" scoped>
.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-left: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 35%;
  min-width: 35rem;
}
.addList {
  /deep/.el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .card-border {
    padding: 1rem;
    margin-top: 1rem;
    background: #f3f3f3;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 32px;
  }
}
.shopDecorate {
  .phone_top {
    padding: 20px 13px 0;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .setBox {
      border: 1px solid #dcdfe6;
      padding: 5px 8px;
      border-radius: 20px;
    }
  }
  .phone_bot {
    border-top: #000 solid 1px;
    padding: 10px 13px 20px;
  }

  .phonebox {
    .top {
      position: relative;
      z-index: 100;
      cursor: pointer;
    }
    width: 375px;
    min-height: 500px;
    height: 750px;
    border: 1px solid #000;
    border-radius: 40px;
    > .phone_content {
      flex: 1;
      padding: 0 13px;
      margin-bottom: 10px;
      > div {
        position: relative;
        margin-bottom: 10px;
      }
    }
    .phone_banner {
      min-height: 150px;
      text-align: center;
      background-color: #999;
    }
    .phone_application {
      width: 100%;
      min-height: 115px;
      // height: auto;
      .application_item {
        width: 25%;
        margin-bottom: 5px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }
    }
    .pushBanner {
      width: 100%;
      height: 120px;
    }
    .Selected {
      // min-height: 300px;
      .course_list {
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        border-bottom: 1px solid #aaa;
        .ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
        }
        .informationDate {
          padding-top: 10px;
          color: #aaa;
        }
      }
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(203, 209, 255, 0.4);
    z-index: 99;
    width: 100%;
    height: 100%;
    border: 2px dashed #001bff;
    display: flex;
    justify-content: flex-end;
    div {
      align-self: flex-end;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 5px;
      }
    }
  }
}
.information-container {
  padding: 1rem;
  background: #f3f3f3;
  .formation {
    padding: 1rem;
    background: #fff;
    margin-top: 1rem;
  }
  .container {
    position: relative;
  }
  .deleteBtn {
    position: absolute;
    right: 23px;
    bottom: 12%;
  }
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }
  .informationDate {
    padding-top: 10px;
    color: #aaa;
  }
}
</style>
