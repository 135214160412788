<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="2%"
    width="50%"
    title="添加资讯"
    :center="true"
    @close="dialogClose"
    :close-on-click-modal="false"
  >
    <section class="">
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="informationId"
            style="width: 100%"
            @selection-change="change"
            stripe
            border
          >
            <el-table-column
              type="selection"
              width="50px"
              align="center"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              label="标题名称"
              align="center"
              prop="informationName"
              show-overflow-tooltip
            />
            <el-table-column
              label="上次更新时间"
              align="center"
              prop="updateTime"
              show-overflow-tooltip
            />
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="dialog-footer" style="margin-top: 10px">
        <div>
          <p>
            已选{{ backArr.length }}门,<el-button
              type="text"
              @click="emptySelected"
              >清空已选</el-button
            >
          </p>
        </div>
        <div>
          <el-button class="bgc-bv" @click="dialogClose" size="small">取消</el-button>
          <el-button class="bgc-bv" @click="doOk" size="small">确定</el-button>
        </div>
      </div>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "relationCourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: {
    //订单Id
    themeId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
     
      backArr: [], //table表格选中数据
      dialogVisible: false, //弹窗显示
    };
  },
  created() {},
  updated() {},
  methods: {
    //table表格选择事件
    change(rowArr) {
      this.backArr = rowArr;
    },
    //弹窗显示
    showPopUp() {
      this.dialogVisible = true;
      this.getData(-1);
     
    },
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        themeId: this.themeId,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      let url = "/biz/et/theme/pageListMessage";
      this.doFetch({
        url: url,
        params,
        pageNum,
      });
    },
    //弹窗确认
    doOk() {
      const { backArr } = this;
      if (backArr.length) {
        this.$emit("eventBus", backArr);
        this.dialogClose();
      } else {
        this.$message({
          message: "请选择要关联的新闻资讯",
          type: "warning",
        });
      }
    },
      //关闭弹窗
    dialogClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
      //   this.$emit("eventBus");
      // this.params = {};
    },
    //清空已选
    emptySelected() {
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
    },
    //获取表格高度
    getTableHeight(opDom = true, page = true) {
      let tHeight = 700 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
